<template>
  <div class="container edit-property">
    <b-button @click="closeModal(property._id)" class="close-modal-btn">
      <i class="fas fa-times"></i>
    </b-button>
    <div class="row body">
      <div class="col-12">
        <div class="property-form">
          <b-form @submit.prevent="EditProperty">
            <div class="row mb-3">
              <div class="col-12 col-lg-6">
                <label for="recommendation">{{
                  $t("property_create.Recommendation")
                }}</label>
                <b-form-textarea
                  id="recommendation"
                  v-model="recommendation"
                  placeholder="Short description about property"
                  rows="3"
                  max-rows="6"
                  required
                ></b-form-textarea>
                <b-form-group
                  id="option"
                  :label="$t('property_create.Option')"
                  label-for="option"
                >
                  <b-form-select
                    id="option"
                    v-model="option"
                    :options="options"
                    required
                  ></b-form-select>
                </b-form-group>

                 <b-form-group
                  id="property_id"
                  :label="$t('property_create.id')"
                  label-for="property_id"
                >
                  <b-form-input
                    id="property_id"
                    v-model="property_id"
                    type="text"
                    required
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  id="type"
                  :label="$t('property_create.Type')"
                  label-for="type"
                >
                  <b-form-select
                    id="type"
                    v-model="type"
                    :options="types"
                    required
                  ></b-form-select>
                </b-form-group>

                <b-form-group
                  id="title"
                  :label="$t('property_create.Title')"
                  label-for="title"
                >
                  <b-form-input
                    id="input-1"
                    type="text"
                    v-model="title"
                    required
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  id="sector"
                  :label="$t('property_create.Sector')"
                  label-for="sector"
                >
                  <b-form-select
                    id="sector"
                    v-model="sector"
                    :options="sectors"
                    required
                    disabled
                  ></b-form-select>
                </b-form-group>

                <div class="card-body p-0 position-relative">
                  <img
                    alt="header"
                    src="@/assets/map/map.png"
                    class="w-100 h-100"
                  />
                  <div>
                    <b-button
                      variant="custom"
                      v-for="(item, index) in areas"
                      :key="index"
                      class="position-absolute map-btn"
                      :class="`btn-${item}`"
                      style="box-shadow: none"
                      @click="sector = item"
                    >
                      <span class="number rounded-circle">{{ item }}</span>
                    </b-button>
                  </div>
                </div>

                <Map
                  @onChangeDistrict="(value) => onChangeDistrict(value)"
                  @onChangeWard="(value) => onChangeWard(value)"
                  @onChangeAddressDetails="
                    (value) => onChangeAddressDetails(value)
                  "
                  :property="property"
                />

                <b-form-group
                  id="square"
                  :label="$t('property_create.Square')"
                  label-for="square"
                >
                  <b-form-input
                    id="square"
                    v-model="square"
                    type="number"
                    required
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  id="cost"
                  :label="$t('property_create.Cost')"
                  label-for="cost"
                >
                  <b-form-input
                    id="cost"
                    v-model="cost"
                    type="number"
                    required
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  id="cost"
                  :label="$t('property_create.rent-in-vnd')"
                  label-for="cost"
                  v-if="rent_in_vnd"
                >
                  <b-form-input
                    id="rent_in_vnd"
                    v-model="rent_in_vnd"
                    type="number"
                  ></b-form-input>
                </b-form-group>
              </div>

              <div class="col-12 col-lg-6">
                <b-form-group
                  id="deposit"
                  :label="$t('property_create.Deposit')"
                  label-for="deposit"
                >
                  <b-form-input
                    id="deposit"
                    v-model="deposit"
                    required
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  id="contract_period"
                  :label="$t('property_create.Contract period')"
                  label-for="contract_period"
                >
                  <b-form-select
                    v-model="contract_period"
                    :options="contract_periods"
                    required
                  ></b-form-select>
                </b-form-group>

                <b-form-group
                  id="plan"
                  :label="$t('property_create.Floor plan')"
                  label-for="plan"
                >
                  <b-form-select
                    v-model="plan"
                    :options="plans"
                    required
                  ></b-form-select>
                </b-form-group>

                <b-form-group
                  id="condition"
                  :label="$t('property_create.Condition')"
                  label-for="condition"
                >
                  <b-form-checkbox-group
                    v-model="condition"
                    :options="conditions"
                    class="mb-3 condition"
                    value-field="value"
                    text-field="text"
                  ></b-form-checkbox-group>
                </b-form-group>

                <b-form-group
                  id="equipment"
                  :label="$t('property_create.Equipment')"
                  label-for="equipment"
                >
                  <b-form-checkbox-group
                    v-model="equipment"
                    :options="equipments"
                    class="mb-3"
                    value-field="value"
                    text-field="text"
                  ></b-form-checkbox-group>
                </b-form-group>

                <b-form-group
                  id="bedroom"
                  :label="$t('property_create.Bedroom')"
                  label-for="bedroom"
                >
                  <b-form-input
                    id="bedroom"
                    v-model="bedroom"
                    type="number"
                    required
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  id="bathroom"
                  :label="$t('property_create.Bathroom')"
                  label-for="bathroom"
                >
                  <b-form-input
                    id="bathroom"
                    v-model="bathroom"
                    type="number"
                    required
                  ></b-form-input>
                </b-form-group>

                <b-overlay :show="isUploading" spinner-variant="success">
                  <div class="drag_images">
                    <draggable
                      :list="list"
                      :disabled="!enabled"
                      class="list-group mb-3"
                      ghost-class="ghost"
                      @start="dragging = true"
                      @end="dragging = false"
                    >
                      <div
                        class="list-group-item"
                        v-for="(element, index) in list"
                        :key="index"
                      >
                        <b-button
                          class="close-btn"
                          @click="deleteItem(element, index)"
                        >
                          <i class="fas fa-times"></i>
                        </b-button>
                        <img
                          :src="element.src"
                          alt="image"
                          style="width: 100%; height: 100%"
                        />
                      </div>
                    </draggable>
                    <div>
                      <input
                        ref="uploadImage"
                        type="file"
                        accept="image/*"
                        multiple
                        @change="processFiles($event)"
                        title="my text "
                      />
                    </div>
                  </div>
                </b-overlay>
              </div>
            </div>

            <div class="text-center">
              <b-button
                type="submit"
                variant="success"
                class="vh text-center px-5 font-weight-bold custom-green-btn"
                align-v="center"
                :disabled="isDisabled"
                >{{ $t("property_create.Submit") }}</b-button
              >
            </div>
          </b-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axiosIns from "../../../libs/axiosConfig";
import draggable from "vuedraggable";
import Map from "../components/Map.vue";
let id = 1;
export default {
  name: "create-property",
  components: { draggable, Map },
  props: ["property"],
  data() {
    return {
      areas: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
      recommendation: this.property.recommendation,
      id: this.property._id,
      property_id: this.property?.property_id || null,
      option: this.property.option,
      type: this.property.type,
      title: this.property.title,
      sector: this.property.sector,
      city: "Danang",
      ward: this.property.address.trim().split(",")[1],
      district: this.property.address.trim().split(",")[2],
      address_details: this.property.address.trim().split(",")[0],
      square: this.property.square,
      cost: this.property.cost,
      rent_in_vnd: this.property.rent_in_vnd,
      utility_costs: this.property.utility_costs,
      wifi_cost: this.property.wifi_cost,
      deposit: this.property.deposit,
      contract_period: this.property.contract_period,
      living_expense: this.property.living_expense,
      plan: this.property.plan,
      condition: this.property.condition,
      equipment: this.property.equipment,
      bathroom: this.property.bathroom,
      bedroom: this.property.bedroom,
      createdAt: this.property.createdAt,
      images: this.property.images,
      isHide: this.property.isHide,
      property_address: this.property.property_address,
      isUploading: false,
      url: process.env.VUE_APP_API_URL,
      enabled: true,
      list: [],
      dragging: false,
      isDisabled: false,
      options: [
        {
          value: null,
          text: this.$t("property_create.Select One"),
          disabled: true,
        },
        { value: "for_rent", text: this.$t("property_create.For Rent") },
        { value: "for_sale", text: this.$t("property_create.For Sale") },
      ],
      types: [
        {
          value: null,
          text: this.$t("property_create.Select One"),
          disabled: true,
        },
        { value: "apartment", text: this.$t("property_create.apartment") },
        { value: "pent-house", text: this.$t("property_create.pent-house") },
        { value: "villa", text: this.$t("property_create.villa") },
        { value: "office", text: this.$t("property_create.office") },
      ],
      sectors: [
        {
          value: null,
          text: this.$t("property_create.Select One"),
          disabled: true,
        },
        { value: "1", text: this.$t("property_create.Area 1") },
        { value: "2", text: this.$t("property_create.Area 2") },
        { value: "3", text: this.$t("property_create.Area 3") },
        { value: "4", text: this.$t("property_create.Area 4") },
        { value: "5", text: this.$t("property_create.Area 5") },
        { value: "6", text: this.$t("property_create.Area 6") },
        { value: "7", text: this.$t("property_create.Area 7") },
        { value: "8", text: this.$t("property_create.Area 8") },
        { value: "9", text: this.$t("property_create.Area 9") },
        { value: "10", text: this.$t("property_create.Area 10") },
        { value: "11", text: this.$t("property_create.Area 11") },
      ],
      living_expenses: [
        { value: "electricity", text: this.$t("property_create.Electricity") },
        { value: "wifi", text: this.$t("property_create.Wifi") },
        { value: "water", text: this.$t("property_create.Water") },
      ],
      contract_periods: [
        {
          value: null,
          text: this.$t("property_create.Select One"),
          disabled: true,
        },
        {
          value: "1-month",
          text: this.$t("property_create.contract-term.1-month"),
        },
        {
          value: "3-month",
          text: this.$t("property_create.contract-term.3-month"),
        },
        {
          value: "6-month",
          text: this.$t("property_create.contract-term.6-month"),
        },
        {
          value: "1-year",
          text: this.$t("property_create.contract-term.1-year"),
        },
        {
          value: "2-year",
          text: this.$t("property_create.contract-term.2-year"),
        },
      ],
      plans: [
        {
          value: null,
          text: this.$t("property_create.Select One"),
          disabled: true,
        },
        {
          value: "studio",
          text: this.$t("sidebar.layout.studio"),
        },
        {
          value: "1ldk",
          text: "1LDK",
        },
        {
          value: "2ldk",
          text: "2LDK",
        },
        {
          value: "3ldk",
          text: "3LDK",
        },
        {
          value: "4ldk",
          text: "4LDK",
        },
        {
          value: "1dk",
          text: "1DK",
        },
        {
          value: "1k",
          text: "1K",
        },
        {
          value: "2dk",
          text: "2DK",
        }
      ],
      conditions: [
        { value: "convenient-store", text: this.$t("property_create.Convenient-store")},
        { value: "supermarket", text: this.$t("property_create.Supermarket") },
        { value: "market", text: this.$t("property_create.Market") },
        { value: "bus-stop", text: this.$t("property_create.Bus Stop") },
        { value: "hospital", text: this.$t("property_create.Hospital") },
        { value: "school", text: this.$t("property_create.School") },
        {
          value: "auto-locking-system",
          text: this.$t("property_create.Auto Locking System"),
        },
        { value: "sea-view", text: this.$t("property_create.Sea View") },
        { value: "pool", text: this.$t("property_create.Pool") },
        { value: "gym", text: this.$t("property_create.Gym") },
        {
          value: "building-mgr",
          text: this.$t("property_create.Building Mgr"),
        },
        {
          value: "international-school",
          text: this.$t("property_create.International School"),
        },
        { value: "pet-allowed", text: this.$t("property_create.Pet Allowed") },
        {
          value: "buy/sell-allowed",
          text: this.$t("property_create.Buy/Sell Allowed"),
        },
      ],
      equipments: [
        {
          value: "gas-electric",
          text: this.$t("property_create.facility.gas-electric"),
        },

        {
          value: "fully-furnished",
          text: this.$t("property_create.facility.fully-furnished"),
        },

        {
          value: "wifi",
          text: this.$t("property_create.facility.wifi"),
        },

        {
          value: "washing-machine",
          text: this.$t("property_create.facility.washing-machine"),
        },

        {
          value: "tv",
          text: this.$t("property_create.facility.tv"),
        },

        {
          value: "ac",
          text: this.$t("property_create.facility.ac"),
        },

        {
          value: "bathtub",
          text: this.$t("property_create.facility.bathtub"),
        },

        {
          value: "shower",
          text: this.$t("property_create.facility.shower"),
        },

        {
          value: "pet-ok",
          text: this.$t("property_create.facility.pet-ok"),
        },

        {
          value: "no-pet",
          text: this.$t("property_create.facility.no-pet"),
        },

        {
          value: "gym",
          text: this.$t("property_create.facility.gym"),
        },
        {
          value: "swimming-pool",
          text: this.$t("property_create.facility.swimming-pool"),
        },
        {
          value: "fire-detecter",
          text: this.$t("property_create.facility.fire-detector"),
        },
        {
          value: "evacuation",
          text: this.$t("property_create.facility.evacuation"),
        },
        {
          value: "mopet-parking",
          text: this.$t("property_create.facility.mopet-parking"),
        },
        {
          value: "cleaning",
          text: this.$t("property_create.facility.cleaning"),
        },
        {
          value: "elevator",
          text: this.$t("property_create.facility.elevator"),
        },
        {
          value: "security",
          text: this.$t("property_create.facility.security"),
        },
        {
          value: "balcony",
          text: this.$t("property_create.facility.balcony"),
        },
      ],
      files: "",
      list_temp: [],
    };
  },
  created() {
    this.list = this.images.map((item) => {
      return {
        src: this.url + item,
      };
    });
  },
  methods: {
    onChangeDistrict(value) {
      this.district = value;
    },
    onChangeAddressDetails(value) {
      this.address_details = value;
    },
    onChangeWard(value) {
      this.ward = value;
    },
    closeModal(id) {
      let list_file = this.list.map((item) =>
        item.src.substring(this.url.length)
      );
      let list_delete = list_file.filter((item) => {
        return !this.images.includes(item);
      });
      if (list_delete.length > 0) {
        axiosIns
          .post("/deleteFile", {
            images: list_delete,
            type: "property",
          })
          .then((res) => {
            // console.log(res);
          });
      }
      this.$bvModal.hide(id);
    },
    deleteItem(element, index) {
      if (this.images.includes(element.src.substring(this.url.length))) {
        this.list_temp.push(element.src.substring(this.url.length));
      } else {
        axiosIns
          .post("/deleteFile", {
            images: [element.src.substring(this.url.length)],
            type: "property",
          })
          .then((res) => {
            // console.log(res);
          });
      }
      this.list.splice(index, 1);
    },
    generateYears() {
      let list = [];
      for (let i = 1960; i <= 2030; i++) {
        list.push(i);
      }
      return list;
    },
    processFiles(event) {
      this.isUploading = true;

      let files = event.target.files;
      let formData = new FormData();
      for (var i = 0; i < files.length; i++) {
        formData.append("files", files[i]);
      }
      axiosIns
        .post("/imgs/property", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          response.data.files_url.forEach((file) => {
            this.list.push({
              src: this.url + file,
            });
          });
          this.isUploading = false;
          this.$refs.uploadImage.value = null;
        })
        .catch(() => {
          console.log("something went wrong!!");
          this.isUploading = false;
          this.$refs.uploadImage.value = null;
        });
    },
    async EditProperty() {
      let property = {
        property_id: this.property_id,
        option: this.option,
        type: this.type,
        title: this.title,
        sector: this.sector,
        address:
          this.address_details +
          ", " +
          this.ward +
          ", " +
          this.district +
          ", " +
          this.city,
        square: this.square,
        cost: this.cost,
        rent_in_vnd: this.rent_in_vnd,
        utility_costs: this.utility_costs ? this.utility_costs : "Included",
        wifi_cost: this.wifi_cost ? this.wifi_cost : "Included",
        deposit: this.deposit ? this.deposit : "NAN",
        contract_period: this.contract_period,
        living_expense: this.living_expense,
        plan: this.plan,
        condition: this.condition,
        equipment: this.equipment,
        bedroom: this.bedroom,
        bathroom: this.bathroom,
        recommendation: this.recommendation,
        createdAt: this.createdAt,
        images: this.list.map((item) => item.src.substring(this.url.length)),
        isHide: this.isHide,
      };
      await axiosIns
        .patch("/property/" + this.id, property)
        .then((response) => {
          axiosIns
            .post("/deleteFile", {
              images: this.list_temp,
              type: "property",
            })
            .then((res) => {
              // console.log(res);
            });
          this.$emit("edit-success", response.data);
          this.$root.$emit("bv::hide::modal", "" + this.id);
        })
        .catch((error) => {
          console.log("ERRRR:: ", error.response);
          this.isDisabled = false;
        });
    },
  },
  mounted() {},
};
</script>

<style lang="scss">
.edit-property {
  position: relative;
  .close-modal-btn {
    position: absolute;
    top: -60px;
    right: 0px;
  }
  .condition {
    display: grid;
  }
  .drag_images {
    input[type="file"] {
      color: rgba(0, 0, 0, 0);
    }
    .list-group {
      display: flex !important;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 10px;
      .list-group-item {
        max-width: 150px;
        position: relative;
        padding: 0;
        border: 0;
        cursor: move;
        img {
          width: 100%;
          height: auto;
        }
        .close-btn {
          position: absolute;
          right: 0px;
          z-index: 1;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 4px 8px;
        }
      }
    }
  }
}

button {
  border: 0;
  background: transparent;
}
span.number {
  background: var(--orange);
  color: white;
  font-weight: 700;
  font-size: 1.2rem;
  width: 26px !important;
  height: 26px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 1024px) {
  span.number {
    font-size: 1rem;
    width: 21px !important;
    height: 21px !important;
  }

  .btn-1 {
    top: 28% !important;
    left: 35% !important;
  }
  .btn-2 {
    top: 24% !important;
    left: 54% !important;
  }
  .btn-3 {
    top: 38% !important;
    left: 35% !important;
  }
  .btn-4 {
    top: 37% !important;
    left: 58% !important;
  }
  .btn-5 {
    top: 50% !important;
    left: 32% !important;
  }
  .btn-6 {
    top: 46% !important;
    left: 60% !important;
  }
  .btn-7 {
    top: 68% !important;
    left: 34% !important;
  }
  .btn-8 {
    top: 57% !important;
    left: 64% !important;
  }
  .btn-9 {
    top: 74% !important;
    left: 68% !important;
  }
  .btn-10 {
    top: 14% !important;
    left: 66% !important;
  }
  .btn-11 {
    top: 34% !important;
    left: 15% !important;
  }
}

.btn-1 {
  top: 30%;
  left: 37%;
}
.btn-2 {
  top: 25%;
  left: 57%;
}
.btn-3 {
  top: 40%;
  left: 38%;
}
.btn-4 {
  top: 39%;
  left: 61%;
}
.btn-5 {
  top: 52%;
  left: 38%;
}
.btn-6 {
  top: 48%;
  left: 64%;
}
.btn-7 {
  top: 71%;
  left: 36%;
}
.btn-8 {
  top: 60%;
  left: 66%;
}
.btn-9 {
  top: 76%;
  left: 70%;
}
.btn-10 {
  top: 16%;
  left: 68%;
}
.btn-11 {
  top: 35%;
  left: 17%;
}
</style>
