<template>
  <div>
    <b-form-group
      id="address"
      :label="$t('property_create.Address')"
      label-for="address"
    >
      <div class="d-flex mb-3">
        <b-form-select
          id="city"
          v-model="address.city"
          @input="(e) => $emit('onChangeCity', e)"
          :options="cities"
          aria-placeholder="Choose city"
          required
        ></b-form-select>
        <b-form-select
          id="district"
          v-model="address.district"
          @input="(e) => $emit('onChangeDistrict', e)"
          :options="districts"
          aria-placeholder="Choose district"
          required
        ></b-form-select>

        <b-form-select
          id="ward"
          v-model="address.ward"
          @input="(e) => $emit('onChangeWard', e)"
          :options="filterWards"
          :disabled="!address.district"
          required
        ></b-form-select>
      </div>

      <b-form-input
        v-model="address.detail"
        @input="(e) => $emit('onChangeAddressDetails', e)"
        type="text"
        placeholder="Details address"
        :disabled="!(address.ward && address.district)"
      ></b-form-input>
    </b-form-group>

    <div id="map">
      <iframe
        width="100%"
        height="250px"
        style="border: 0"
        loading="lazy"
        allowfullscreen
        :src="src"
      >
      </iframe>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      address: {
        detail: "",
        city: "danang",
        district: "",
        ward: "",
      },
      src: "https://www.google.com/maps/embed/v1/place?key=AIzaSyCPYX-df-wDth7pRnxf_L8SNIaedHeK-Mo&q=danang",
      cities: [{ value: "danang", text: "Danang" }],
      districts: [
        { value: null, text: "Choose district" },
        { value: "hai chau", text: "Hai Chau" },
        { value: "ngu hanh son", text: "Ngu Hanh Son" },
        { value: "thanh khe", text: "Thanh Khe" },
        { value: "lien chieu", text: "Lien Chieu" },
        { value: "cam le", text: "Cam Le" },
        { value: "son tra", text: "Son Tra" },
        { value: "hoa vang", text: "Hoa Vang" },
      ],
      filter_wards: [{ value: null, text: "Choose ward" }],
      wards: {
        "hai chau": [
          { value: null, text: "Choose ward" },
          { value: "thanh binh", text: "Thanh Binh" },
          { value: "thuan phuoc", text: "Thuan Phuoc" },
          { value: "thach thang", text: "Thach Thang" },
          { value: "hai chau 1", text: "Hai Chau 1" },
          { value: "hai chau 2", text: "Hai Chau 2" },
          { value: "phuoc ninh", text: "Phuoc Ninh" },
          { value: "hoa thuan tay", text: "Hoa Thuan Tay" },
          { value: "hoa thuan dong", text: "Hoa Thuan Dong" },
          { value: "nam duong", text: "Nam Duong" },
          {value: "binh hien", text: "Binh Hien"},
          {value: "binh thuan", text: "Binh Thuan"},
          {value: "hoa cuong bac", text: "Hoa Cuong Bac"},
          {value: "hoa cuong nam", text: "Hoa Cuong Nam"},
        ],

        "cam le": [
          { value: null, text: "Choose ward" },
          { value: "khue trung", text: "Khue Trung" },
          { value: "hoa tho dong", text: "Hoa Tho Dong" },
          { value: "hoa tho tay", text: "Hoa Tho Tay" },
          { value: "hoa an", text: "Hoa An" },
          { value: "hoa phat", text: "Hoa Phat" },
          { value: "hoa xuan", text: "Hoa Xuan" },
        ],

        "thanh khe": [
          { value: null, text: "Choose ward" },
          { value: "vinh trung", text: "Vinh Trung" },
          { value: "tan chinh", text: "Tan Chinh" },
          { value: "thac gian", text: "Thac Gian" },
          { value: "chinh gian", text: "Chinh Gian" },
          { value: "tam thuan", text: "Tam Thuan" },
          { value: "xuan ha", text: "Xuan Ha" },
          { value: "an khe", text: "An Khe" },
          { value: "hoa khe", text: "Hoa Khe" },
          { value: "thanh khe dong", text: "Thanh Khe Dong" },
          { value: "thanh khe tay", text: "Thanh Khe Tay" },
        ],

        "lien chieu": [
          { value: null, text: "Choose ward" },
          { value: "hoa minh", text: "Hoa Minh" },
          { value: "hoa khanh nam", text: "Hoa Khanh Nam" },
          { value: "hoa khanh bac", text: "Hoa Khanh Bac" },
          { value: "hoa hiep nam", text: "Hoa Hiep Nam" },
          { value: "hoa hiep bac", text: "Hoa Hiep Bac" },
        ],

        "ngu hanh son": [
          { value: null, text: "Choose ward" },
          { value: "my an", text: "My An" },
          { value: "khue my", text: "Khue My" },
          { value: "hoa hai", text: "Hoa Hai" },
          { value: "hoa quy", text: "Hoa Quy" },
        ],

        "son tra": [
          { value: null, text: "Choose ward" },
          { value: "an hai bac", text: "An Hai Bac" },
          { value: "an hai dong", text: "An Hai Dong" },
          { value: "an hai tay", text: "An Hai Tay" },
          { value: "man thai", text: "Man Thai" },
          { value: "nai hien dong", text: "Nai Hien Dong" },
          { value: "phuoc my", text: "Phuoc My" },
          { value: "tho quang", text: "Tho Quang" },
        ],

        "hoa vang": [
          { value: null, text: "Choose ward" },
          { value: "hoa bac", text: "Hoa Bac" },
          { value: "hoa lien", text: "Hoa Lien" },
          { value: "hoa ninh", text: "Hoa Ninh" },
          { value: "hoa son", text: "Hoa Son" },
          { value: "hoa nhon", text: "Hoa Nhon" },
          { value: "hoa phu", text: "Hoa Phu" },
          { value: "hoa phong", text: "Hoa Phong" },
          { value: "hoa chau", text: "Hoa Chau" },
          { value: "hoa tien", text: "Hoa Tien" },
          { value: "hoa phuoc", text: "Hoa Phuoc" },
          { value: "hoa khuong", text: "Hoa Khuong" },
        ],
      },
    };
  },
  props: ["property"],
  methods: {},
  computed: {
    query() {
      return (
        this.address.city +
        this.address.district +
        this.address.ward +
        this.address.detail
      );
    },
    filterWards() {
      return this.wards[this.address.district];
    },
    splitAddress() {
      return this.property.address.trim().split(",");
    },
  },
  mounted() {
    if (this.property.address) {
      this.address.detail =
        this.splitAddress.length > 3 ? this.splitAddress[0].trim() : "";
      this.address.district =
        this.splitAddress.length > 3
          ? this.splitAddress[2].trim()
          : this.splitAddress[1].trim();
      this.address.ward =
        this.splitAddress.length > 3
          ? this.splitAddress[1].trim()
          : this.splitAddress[0].trim();
    }
  },
  watch: {
    query() {
      if (this.address.city && this.address.district && this.address.ward) {
        this.src = `https://www.google.com/maps/embed/v1/place?key=AIzaSyCPYX-df-wDth7pRnxf_L8SNIaedHeK-Mo&q=${this.address.city}+${this.address.district}+${this.address.ward}`;
      }
    },
  },
};
</script>

<style></style>
