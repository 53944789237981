<template>
  <div class="d-flex flex-column explore__card">
    <div class="card-header bg-success text-white font-weight-bold">
      <router-link :to="`/property/${id}`" class="card-link">
        <span>{{ title }}</span>
      </router-link>
      <div v-if="route_name === 'CreateProperty'" class="admin-buttons mt-1">
        <b-button
          @click="$bvModal.show(`${id}`)"
          ok-disabled
          class="text-success bg-white border-0 admin-button"
          >{{ $t("property_create.Edit") }}</b-button
        >
        <b-button
          @click="$bvModal.show(`delete-${id}`)"
          class="text-success bg-white border-0 admin-button"
          >{{ $t("property_create.Delete") }}</b-button
        >
        <b-button
          @click="changeStatus"
          class="text-white bg-info border-info admin-button"
          >{{
            hide ? $t("property_create.Unhide") : $t("property_create.Hide")
          }}</b-button
        >
      </div>
      <b-modal
        :id="`delete-${id}`"
        size="md"
        :title="$t('property_create.Delete Property')"
        @ok="deleteProperty"
        :ok-title="$t('property_create.Yes')"
        :cancel-title="$t('property_create.No')"
      >
        {{
          $t("property_create.Are you sure to delete this property")
        }}</b-modal
      >

      <b-modal
        :id="property._id"
        :title="$t('property_create.Edit Property')"
        :hide-footer="true"
        :hide-header-close="true"
        :no-close-on-backdrop="true"
        :no-close-on-esc="true"
        size="xl"
      >
        <EditProperty
          :id="property._id"
          :option="property.option"
          :type="property.type"
          :title="property.title"
          :sector="property.sector"
          :property_address="property.address"
          :square="property.square"
          :cost="property.cost"
          :utility_costs="property.utility_costs"
          :wifi_cost="property.wifi_cost"
          :deposit="property.deposit"
          :contract_period="property.contract_period"
          :living_expense="property.living_expense"
          :plan="property.plan"
          :condition="property.condition"
          :equipment="property.equipment"
          :bathroom="property.bathroom"
          :bedroom="property.bedroom"
          :createdAt="property.createdAt"
          :images="property.images"
          :isHide="property.isHide"
          @edit-success="editSuccess"
          :property="property"
        />
      </b-modal>
    </div>
    <div class="container card-body mb-3">
      <div class="row">
        <div class="col-6">
          <router-link :to="`/property/${id}`" class="card-link">
            <img :src="url + image" alt="img" class="w-100 h-100 card-image" />
          </router-link>
        </div>

        <div class="card-details col-6 px-0">
          <div class="container">
            <div class="row">
              <p class="col-7">{{ $t("card.Floor plan") }}</p>
              <p class="col-5 text-uppercase">{{ plan }}</p>
            </div>
          </div>

          <div class="container">
            <div class="row">
              <p class="col-7">{{ $t("card.Cost") }}</p>
              <p class="col-5">{{ cost }} $</p>
            </div>
          </div>

          <div class="container" v-if="rent_in_vnd">
            <div class="row">
              <p class="col-7">{{ $t("card.rent-in-vnd") }}</p>
              <p class="col-5">{{ rent_in_vnd }}</p>
            </div>
          </div>

          <div class="container">
            <div class="row">
              <p class="col-7">{{ $t("card.Area") }}</p>
              <p class="col-5">{{ area }} m&#178;</p>
            </div>
          </div>

          <div class="container">
            <div class="row">
              <p class="col-7 text-nowrap">{{ $t("card.Bedroom") }}</p>
              <p class="col-5">{{ bedroom }}</p>
            </div>
          </div>

          <div class="container">
            <div class="row">
              <p class="col-7">{{ $t("card.Bathroom") }}</p>
              <p class="col-5">{{ bathroom }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EditProperty from "@/views/admin/components/EditProperty";
export default {
  name: "Card",
  props: [
    "id",
    "neighborhood",
    "title",
    "image",
    "bathroom",
    "bedroom",
    "area",
    "wifi_cost",
    "utility_cost",
    "cost",
    "address",
    "plan",
    "route_name",
    "property",
    "rent_in_vnd",
  ],
  components: { EditProperty },
  data() {
    return {
      url: process.env.VUE_APP_API_URL,
      hide: this.property.isHide,
    };
  },
  methods: {
    deleteProperty() {
      this.$emit("deleteProperty", this.id);
    },
    editSuccess(data) {
      this.$emit("updateProperty", data);
    },
    changeStatus() {
      this.$emit("hideProperty", this.id);
      this.hide = !this.hide;
    },
  },
};
</script>

<style lang="scss" scoped>
.card-link:hover {
  text-decoration: none;
  color: inherit;
}
@media screen and (min-width: 1024px) {
  p.col-7, p.col-5 {
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
  }
}

@media screen and (max-width: 767px) {
  p.col-5 {
    margin-bottom: 5px;
  }
}
p.col-7 {
  border-right: 1px solid var(--success);
  margin-bottom: 0px !important;
  background-color: var(--bg);
}
.explore__card {
  .card-body {
    padding: 15px;
    border: 3px solid var(--success);
    border-top: 0;
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }
  .card-header {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }
  .admin-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    .admin-button {
      min-width: 70px;
    }
  }
}
.card-image {
  object-fit: cover;
}
.surrounding_content {
  &::after {
    content: "、";
  }
  &:last-child::after {
    content: none;
  }
}

@media screen and (max-width: 576px) {
  .card-details {
    .container {
      .row {
        p {
          font-size: 0.8rem;
        }
      }
    }
  }
}
</style>
